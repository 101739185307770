<template>
  <VueApexCharts
      type="bar"
      width="100%"
      :height="height"
      :options="chartOptions"
      :series="series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "GraficoBarraHorizontalDashboard",
  components: { VueApexCharts },
  props: {
    dataSeries: { type: Array, required: true },
    categories: { type: Array, required: true },
    maxValues: { type: Array, required: true }, // Limites para cada categoria
    height: { type: [Number, String], default: 430 },
    colors: { type: Array, default: () => ["#1ab7ea", "#0084ff"] },
    seriesLabels: { type: Array, default: () => ["Série 1", "Série 2"] },
    percent: { type: Boolean, default: true },
    barHorizontal: { type: Boolean, default: true },
    barHeight: { type: [Number, String], default: "70%" },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            horizontal: this.barHorizontal,
            barHeight: this.barHeight,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (value, { dataPointIndex }) =>
              this.percent
                  ? `${(value * this.maxValues[dataPointIndex]).toFixed(2)}%`
                  : value, //
          offsetX: 10,
          style: {
            fontSize: "12px",
            fontWeight: "bold",
            colors: ["#002D56"],
          },
          background: {
            enabled: true,
            borderColor: "#fff",
            opacity: 0.5,
          },
        },
        stroke: {
          show: false,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (value, { dataPointIndex }) =>
                this.percent
                    ? `${(value * this.maxValues[dataPointIndex]).toFixed(2)}%`
                    : value
          },
        },
        xaxis: {
          categories: [],
          decimalsInFloat: 1,
          min: 0,
          max: this.percent ? 1 : Math.max(...this.maxValues),
          labels: {
            show: !this.barHorizontal,
            style: {
              fontSize: "14px",  // Tamanho da fonte
              fontWeight: "bold", // Peso da fonte (negrito)
              colors: "#313131",  // Cor do texto
            },
          },
        },
        yaxis: {
          categories: [],
          decimalsInFloat: 1,
          min: 0,
          max: this.percent ? 1 : Math.max(...this.maxValues),
          labels: {
            show: this.barHorizontal,
            style: {
              fontSize: "14px",  // Tamanho da fonte
              fontWeight: "bold", // Peso da fonte (negrito)
              colors: "#313131",  // Cor do texto
            },
          },
        },
        colors: [],
        legend: {
          labels: {
            colors: "#313131", // Cor da legenda
            useSeriesColors: false, // Se `true`, usa a cor da série em vez da definida aqui
          },
          fontSize: "17px",
          fontWeight: "bold",
        },
      },
    };
  },
  created() {
    this.series = this.dataSeries.map((serie, index) => ({
      name: this.seriesLabels[index],
      data: this.percent
          ? serie.data.map((value, i) => value / this.maxValues[i]) // Normalizado
          : serie.data,
    }));

    this.chartOptions.xaxis.categories = this.categories;
    this.chartOptions.colors = this.colors;
  },
};
</script>