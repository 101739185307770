<template>
  <div>
    <apexchart
        :options="chartOptions"
        :series="series"
        type="donut"
        :height="400"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    colors: {
      type: Array,
      default: () => ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
    },
    series: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "Department Sales",
    },
    fontSize: {
      type: String,
      default: "18px",
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "donut",
          width: "100%",
          height: 400,
        },
        dataLabels: {
          enabled: true, // Ativar valores sobre as barras
          style: {
            fontSize: "12px",
            colors: ["#ffffff"], // Cor do texto
          },
          formatter: function (val) {
            return `${val.toFixed(2)}%`;
          },
        },
        plotOptions: {
          pie: {
            customScale: 0.8,
            donut: {
              size: "75%",
            },
            offsetY: 20,
          },
        },
        colors: this.colors,
        title: {
          text: this.title,
          style: {
            color: "#222",
            fontWeight: "bold",
            fontSize: "18px",
          },
        },
        labels: this.labels,
        legend: {
          position: "left",
          offsetY: 40,
        },
      };
    },
  },
};
</script>
