<template xmlns:v-card="http://www.w3.org/1999/html">
  <div>

    <v-dialog v-model="dialogVisitasVendas">
      <v-card class="primary">
        <v-card-title class="pl-4 pr-3 white--text">
          {{ tituloDialog }} - Rede
          <v-spacer/>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-skeleton-loader v-if="carregando2" type="table-tbody"/>

          <!--          <v-data-table v-else :headers="[-->
          <!--						{ text: 'Data', value: 'data', class: 'text-no-wrap', sortable: false },-->
          <!--						{ text: 'Meta Diária Fixa', value: 'metadiariafixa', class: 'text-no-wrap', sortable: false },-->
          <!--						{ text: 'Meta Remanescente', value: 'metaremanescente', class: 'text-no-wrap', sortable: false },-->
          <!--						{ text: 'Venda do Dia', value: 'vendadiaria', class: 'text-no-wrap', sortable: false },-->
          <!--						{ text: 'Meta Acumulada', value: 'metadiaria', class: 'text-no-wrap', sortable: false },-->
          <!--						{ text: 'Saldo', value: 'realizado', class: 'text-no-wrap', sortable: false },-->
          <!--						{ text: 'Atingimento', value: 'atingimento', class: 'text-no-wrap', sortable: false },-->
          <!--						{ text: 'Dias Úteis', value: 'diasuteis', class: 'text-no-wrap', sortable: false },-->
          <!--					]" :items="detalheMetaDiariaAcumulada" hide-default-footer disable-pagination mobile-breakpoint="0" fixed-header-->
          <!--                        height="60vh">-->

          <!--            <template v-slot:item.data="{ item }">-->
          <!--              <span class="text-truncate pr-0">{{ item.data }}</span>-->
          <!--            </template>-->

          <!--            <template v-slot:item.metadiariafixa="{ item }">-->
          <!--              <span class="pr-0">{{ item.metadiariafixa | formataDinheiro }}</span>-->
          <!--            </template>-->

          <!--            <template v-slot:item.metaremanescente="{ item }">-->
          <!--              <span>{{ item.metaremanescente | formataDinheiro }}</span>-->
          <!--            </template>-->

          <!--            <template v-slot:item.vendadiaria="{ item }">-->
          <!--              <span>{{ item.vendadiaria | formataDinheiro }}</span>-->
          <!--            </template>-->

          <!--            <template v-slot:item.metadiaria="{ item }">-->
          <!--              <span>{{ item.metadiaria | formataDinheiro }}</span>-->
          <!--            </template>-->

          <!--            <template v-slot:item.realizado="{ item }">-->
          <!--              <span>{{ item.realizado | formataDinheiro }}</span>-->
          <!--            </template>-->

          <!--            <template v-slot:item.atingimento="{ item }">-->
          <!--              <span>{{ item.atingimento }}%</span>-->
          <!--            </template>-->

          <!--            <template v-slot:item.diasuteis="{ item }">-->
          <!--              <span class="text-center">{{ item.diasuteis }}</span>-->
          <!--            </template>-->

          <!--          </v-data-table>-->

        </v-card-text>
        <v-divider/>
        <v-card-actions class="pa-3">
          <v-spacer/>
          <v-btn class="px-5" color="primary" elevation="0" @click="dialogVisitasVendas = false; tituloDialog = '';">
            Fechar
          </v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>

    </v-dialog>


    <v-row no-gutters class="colors-dashboard">
      <v-col cols="12">
        <v-card class="colors-dashboard">

          <!-- FILTROS -->
          <v-card-title class="pa-2 white" flat>
            <v-col cols="12">
              <!--              <v-form v-if="permissao('controle_visitas_resumo_diretoria')">-->
              <!--                <v-row class="mb-n10">-->

              <!--&lt;!&ndash;                  <v-col class="d-none d-md-block pl-2 pr-1 pl-lg-3" >&ndash;&gt;-->
              <!--&lt;!&ndash;                    <InputDatePicker :disabled="carregando" v-model="busca.dtinicio"&ndash;&gt;-->
              <!--&lt;!&ndash;                                     label="Data inicial" :outlined="true" :dense="true"/>&ndash;&gt;-->
              <!--&lt;!&ndash;                  </v-col>&ndash;&gt;-->
              <!--&lt;!&ndash;                  <v-col class="d-none d-md-block px-1" >&ndash;&gt;-->
              <!--&lt;!&ndash;                    <InputDatePicker :disabled="carregando" v-model="busca.dtfim" label="Data final"&ndash;&gt;-->
              <!--&lt;!&ndash;                                     :outlined="true" :dense="true"/>&ndash;&gt;-->
              <!--&lt;!&ndash;                  </v-col>&ndash;&gt;-->

              <!--                  <v-select label="Período" filled dense :items="periodos" item-text="data" item-value="data"-->
              <!--                            :disabled="carregando" v-model="busca.periodo.data" class="d-none d-md-block"-->
              <!--                            hide-details></v-select>-->

              <!--                  <v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">-->
              <!--                    <v-sheet class="text-center" height="100%">-->
              <!--                      <v-row class="pa-3">-->
              <!--&lt;!&ndash;                        <v-col cols="6" class="px-2">&ndash;&gt;-->
              <!--&lt;!&ndash;                          <InputDatePicker :disabled="carregando" v-model="busca.dtinicio"&ndash;&gt;-->
              <!--&lt;!&ndash;                                           label="Data inicial" :outlined="true" :dense="true"/>&ndash;&gt;-->
              <!--&lt;!&ndash;                        </v-col>&ndash;&gt;-->
              <!--&lt;!&ndash;                        <v-col cols="6" class="px-2">&ndash;&gt;-->
              <!--&lt;!&ndash;                          <InputDatePicker :disabled="carregando" v-model="busca.dtfim"&ndash;&gt;-->
              <!--&lt;!&ndash;                                           label="Data final" :outlined="true" :dense="true"/>&ndash;&gt;-->
              <!--&lt;!&ndash;                        </v-col>&ndash;&gt;-->
              <!--                        <v-col cols="12">-->
              <!--                          <v-select label="Período" filled dense :items="periodos" item-text="data" item-value="data"-->
              <!--                                    :disabled="carregando" v-model="busca.periodo.data"-->
              <!--                                    hide-details></v-select>-->
              <!--                        </v-col>-->

              <!--                        <v-col cols="6">-->
              <!--                          <v-btn text class="mt-n3" color="primary"-->
              <!--                                 @click="sheet = !sheet">Cancelar-->
              <!--                          </v-btn>-->
              <!--                        </v-col>-->
              <!--                        <v-col cols="6">-->
              <!--                          <v-btn text class="mt-n3" color="primary"-->
              <!--                                 @click="carrega(); sheet = !sheet">Filtrar-->
              <!--                          </v-btn>-->
              <!--                        </v-col>-->
              <!--                      </v-row>-->
              <!--                    </v-sheet>-->
              <!--                  </v-bottom-sheet>-->
              <!--                  <v-col cols="12" md="4" class="pr-1 pl-3 pl-md-1 pr-lg-3 d-flex">-->
              <!--                    <v-autocomplete :items="filiaisFiltro" clearable item-text="filial"-->
              <!--                                    item-value="idfilial" label="Filial" outlined dense :disabled="carregando"-->
              <!--                                    v-model="busca.idfiliais" multiple class="d-inline-flex d-md-none"-->
              <!--                                    append-outer-icon="mdi-menu" @click:append-outer="sheet = !sheet"-->

              <!--                    >-->
              <!--                      <template v-slot:selection="{ item, index }">-->
              <!--                        <template v-if="index === 0">{{ item.filial }}</template>-->
              <!--                        <span v-if="index === 1" class="grey&#45;&#45;text text-caption ml-1">(+{{-->
              <!--                            busca.idfiliais.length - 1-->
              <!--                          }})</span>-->
              <!--                      </template>-->
              <!--                    </v-autocomplete>-->
              <!--&lt;!&ndash;                    <v-autocomplete :items="filiaisFiltro" clearable item-text="filial"&ndash;&gt;-->
              <!--&lt;!&ndash;                                    item-value="idfilial" label="Filial" outlined dense :disabled="carregando"&ndash;&gt;-->
              <!--&lt;!&ndash;                                    v-model="busca.idfiliais" multiple class="d-none d-md-block"&ndash;&gt;-->
              <!--&lt;!&ndash;                                    >&ndash;&gt;-->
              <!--&lt;!&ndash;                      <template v-slot:selection="{ item, index }">&ndash;&gt;-->
              <!--&lt;!&ndash;                        <template v-if="index === 0">{{ item.filial }}</template>&ndash;&gt;-->
              <!--&lt;!&ndash;                        <span v-if="index === 1" class="grey&#45;&#45;text text-caption ml-1">(+{{
                &ndash;&gt;-- >
                < !-- &lt; ! & ndash;                            busca.idfiliais.length - 1 & ndash;&gt;-- >
                < !-- &lt; ! & ndash;
              }})</span>&ndash;&gt;-->
              <!--&lt;!&ndash;                      </template>&ndash;&gt;-->
              <!--&lt;!&ndash;                    </v-autocomplete>&ndash;&gt;-->

              <!--                    <v-btn :disabled="carregando" class="ml-2 mt-1" color="primary" elevation="0"-->
              <!--                           fab x-small @click="carrega()">-->
              <!--                      <v-icon>mdi-magnify</v-icon>-->
              <!--                    </v-btn>-->
              <!--                  </v-col>-->
              <!--                </v-row>-->
              <!--              </v-form>-->
              <v-form>
                <v-row class="mb-n4">
                  <v-col class="px-1 pl-md-1 pr-lg-3 d-flex">

                    <!--                    <v-col class="mt-n3 mb-3">-->
                    <!--                      <InputDatePicker-->
                    <!--                          :disabled="carregando"-->
                    <!--                          v-model="busca.dtinicio"-->
                    <!--                          label="Data inicial"-->
                    <!--                          :outlined="true"-->
                    <!--                          :dense="true"-->
                    <!--                      />-->
                    <!--                    </v-col>-->
                    <!--                    <v-col class="mt-n3 mb-3 px-0">-->
                    <!--                      <InputDatePicker-->
                    <!--                          :disabled="carregando"-->
                    <!--                          v-model="busca.dtfim"-->
                    <!--                          label="Data final"-->
                    <!--                          :outlined="true"-->
                    <!--                          :dense="true"-->
                    <!--                      />-->
                    <!--                    </v-col>-->

                    <v-select label="Período" filled dense :items="periodos" item-text="data" item-value="data"
                              :disabled="carregando" v-model="busca.periodo.data"
                              hide-details></v-select>

                    <v-btn
                        :disabled="carregando"
                        class="ml-2 mt-1"
                        color="primary"
                        elevation="0"
                        fab
                        x-small
                        @click="carrega()"
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
<!--              <v-form v-if="permissao('controle_visitas_resumo_gerente')">-->
<!--                <v-row class="mb-n4">-->
<!--                  <v-col class="px-1 pl-md-1 pr-lg-3 d-flex">-->

<!--                    &lt;!&ndash;                    <v-col class="mt-n3 mb-3">&ndash;&gt;-->
<!--                    &lt;!&ndash;                      <InputDatePicker&ndash;&gt;-->
<!--                    &lt;!&ndash;                          :disabled="carregando"&ndash;&gt;-->
<!--                    &lt;!&ndash;                          v-model="busca.dtinicio"&ndash;&gt;-->
<!--                    &lt;!&ndash;                          label="Data inicial"&ndash;&gt;-->
<!--                    &lt;!&ndash;                          :outlined="true"&ndash;&gt;-->
<!--                    &lt;!&ndash;                          :dense="true"&ndash;&gt;-->
<!--                    &lt;!&ndash;                      />&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </v-col>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    <v-col class="mt-n3 mb-3 px-0">&ndash;&gt;-->
<!--                    &lt;!&ndash;                      <InputDatePicker&ndash;&gt;-->
<!--                    &lt;!&ndash;                          :disabled="carregando"&ndash;&gt;-->
<!--                    &lt;!&ndash;                          v-model="busca.dtfim"&ndash;&gt;-->
<!--                    &lt;!&ndash;                          label="Data final"&ndash;&gt;-->
<!--                    &lt;!&ndash;                          :outlined="true"&ndash;&gt;-->
<!--                    &lt;!&ndash;                          :dense="true"&ndash;&gt;-->
<!--                    &lt;!&ndash;                      />&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </v-col>&ndash;&gt;-->

<!--                    <v-select label="Período" filled dense :items="periodos" item-text="data" item-value="data"-->
<!--                              :disabled="carregando3" v-model="busca.periodo.data"-->
<!--                              hide-details></v-select>-->

<!--                    <v-btn-->
<!--                        :disabled="carregando3"-->
<!--                        class="ml-2 mt-1"-->
<!--                        color="primary"-->
<!--                        elevation="0"-->
<!--                        fab-->
<!--                        x-small-->
<!--                        @click="carrega()"-->
<!--                    >-->
<!--                      <v-icon>mdi-magnify</v-icon>-->
<!--                    </v-btn>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-form>-->
            </v-col>
          </v-card-title>

          <v-divider/>
          <div v-if="permissao('controle_visitas_resumo_diretoria')">
            <!-- Graficos Mensal, Semanal e Diario -->
            <v-skeleton-loader light v-if="carregando" type="card-heading, image, actions"/>
            <v-card-text v-else>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                  <v-card class="pt-2">
                    <GraficoSparkline1
                        :dataSeries="[
                            {
                              name: 'Visitas',
                              data: visitasMes.visitasDias
                            }
                        ]"
                        :labels="visitasMes.dias"
                        :colors="['#1D5092']"
                        :title="String(this.visitasCards.TOTAL_MENSAL)"
                        subtitle="Visitas Mensais"
                    />
                  </v-card>

                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                  <v-card class="pt-2">
                    <GraficoSparkline2
                        :dataSeries="[{
                          name: 'Visitas',
                          data: visitasSemana.visitasSemana
                        }]"
                        :labels="visitasSemana.semana"
                        :colors="['#9F272C']"
                        :title="String(this.visitasCards.TOTAL_MEDIA_SEMANAL)"
                        subtitle="Média Semanal"
                    />
                  </v-card>

                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                  <v-card class="pt-2">
                    <GraficoSparkline3
                        :dataSeries="[{
                          name: 'Visitas',
                          data: visitasDiario.visitasHoras
                        }]"
                        :labels="visitasDiario.horas"
                        :colors="['#F2CB05']"
                        :title="String(this.visitasCards.TOTAL_DIARIO)"
                        :subtitle="subSemanal"
                    />
                  </v-card>

                </v-col>
              </v-row>

            </v-card-text>
            <v-divider/>

            <v-skeleton-loader light v-if="carregando" type="card-heading, image, actions"/>
            <v-card-text v-else class="pt-0">
              <v-row class="my-2">
                <v-col cols="12">
                  <v-card class="pa-0">
                    <v-card-title>
                      <span style="font-weight: 500;">Visitas X Vendas</span>
<!--                      <v-spacer/>-->
<!--                      <v-btn :disabled="carregando" class="px-2 mb-1 ml-auto mt-2 mr-2" color="primary"-->
<!--                             elevation="0" small @click="abrirDialog('Visitas X Vendas')">-->
<!--                        <v-icon size="18">mdi-magnify-plus-outline</v-icon>-->
<!--                        Ver mais-->
<!--                      </v-btn>-->
                    </v-card-title>
                    <v-divider/>
                    <v-card-text v-if="carregando">
                      <v-skeleton-loader class="mx-auto" width="100%" height="300"
                                         type="image, image"></v-skeleton-loader>
                    </v-card-text>
                    <v-card-text v-else-if="$vuetify.breakpoint.smAndDown" class="pl-2 pr-2">
                      <GraficoLineHorizontal
                          :dataSeries="[
                        { data: this.visitasEVendas.map(v => parseInt(v.QTD_VISITA)) },
                        { data: this.visitasEVendas.map(v => parseInt(v.QTD_PEDIDO)) }
                        ]"
                          :categories="this.visitasEVendas.map(v => v.EMPRESA)"
                          :maxValues="this.visitasEVendas.map(v => parseInt(v.QTD_VISITA))"
                          :colors="['#1D5092', '#4CAF50']"
                          :seriesLabels="['Visitas Realizadas', 'Pedidos de Venda Efetivados']" :height="1100" :percent="false"
                          :barHeight="'90%'"/>
                    </v-card-text>
                    <v-card-text v-else>
                      <GraficoLineHorizontal
                          :dataSeries="[
                        { data: this.visitasEVendas.map(v => parseInt(v.QTD_VISITA)) },
                        { data: this.visitasEVendas.map(v => parseInt(v.QTD_PEDIDO)) }
                        ]"
                          :categories="this.visitasEVendas.map(v => v.EMPRESA)"
                          :maxValues="this.visitasEVendas.map(v => parseInt(v.QTD_VISITA))"
                          :colors="['#1D5092', '#4CAF50']"
                          :seriesLabels="['Visitas Realizadas', 'Pedidos de Venda Efetivados']" :height="500" :percent="false"
                          :barHorizontal="false"/>
                    </v-card-text>

                  </v-card>

                </v-col>
              </v-row>

              <v-row class="my-2">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" v-if="$vuetify.breakpoint.mdAndUp">
                  <v-card class="pa-0">
                    <GraficoBarDashVisitas
                        class="pt-4 pl-4 pr-4 pb-0"
                        title="Visitas por Filial"
                        :series="[{
                          name: 'Visitas realizadas',
                          data: this.visitasFiliais.TOTAL_VISITA_FILIAL.map(v => v.QTD_VISITA) }]"
                        :categories="this.visitasFiliais.TOTAL_VISITA_FILIAL.map(v => v.EMPRESA)"
                        :colors="['#1D5092', '#9F272C']"
                        :height="tamanhoGraficoBarras"
                    />
                    <v-divider/>
                    <div>
                      <span
                          class="text_total_visitas text-h5">Total de Visitas rede: {{
                          this.visitasFiliais.TOTAL_VISITAS
                        }}</span>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" v-else>
                  <v-card class="pa-0" v-if="visitasFiliais || visitasFiliais.length === 0">
                    <GraficoBarDashVisitas
                        class="pt-2"
                        title="Visitas por Filial"
                        :series="[{
                          name: 'Visitas realizadas',
                          data: this.visitasFiliais.TOTAL_VISITA_FILIAL.map(v => v.QTD_VISITA) }]"
                        :categories="this.visitasFiliais.TOTAL_VISITA_FILIAL.map(v => v.EMPRESA)"
                        :colors="['#1D5092', '#9F272C']"
                        :height="tamanhoGraficoBarras"
                    />
                    <v-divider/>
                    <div>
                      <span class="text_total_visitas text-h5">Total de Visitas rede: {{ totalVisitasRede }}</span>
                    </div>
                  </v-card>
                  <span v-else>Não há dados para carregar no gráfico</span>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="6" lg="6">
                  <v-card class="pa-0" v-if="visitasEtapas || visitasEtapas.length === 0">
                    <GraficoDonut
                        class="pa-4"
                        :title="'Visitas por etapa'"
                        :colors="colorPalette"
                        :series="this.visitasEtapas.map(v => parseInt(v.QTD))"
                        :labels="this.visitasEtapas.map(v => v.ETAPA)"
                        :height="600"
                    />
                  </v-card>
                  <v-card v-else>
                    <span>Não há dados para carregar no gráfico</span>
                  </v-card>

                </v-col>
                <v-col v-else cols="12">
                  <v-card>
                    <v-progress-linear indeterminate absolute :active="carregando"/>
                    <v-card-text v-if="carregando">
                      <v-skeleton-loader class="mx-auto" width="100%" height="300"
                                         type="image, image"></v-skeleton-loader>
                    </v-card-text>
                    <v-card-text v-else>
                      <GraficoBarDashVisitas
                          class="pa-0"
                          title="Visitas por Etapa"
                          :series="[{
                          name: 'Visitas realizadas',
                          data: this.visitasEtapas.map(v => parseInt(v.QTD)) }]"
                          :categories="this.visitasEtapas.map(v => v.ETAPA)"
                          :colors="[ '#9F272C']"
                          :height="400"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!-- PLUS A FAZER DEPOIS -->
              <!--            <v-row>-->
              <!--              <v-col cols="12" class="pt-0">-->
              <!--                <v-card color="white">-->
              <!--                  <v-progress-linear indeterminate absolute :active="carregando" />-->
              <!--                  <v-card-title>-->
              <!--                    <span class="text-truncate">Visitas em aberto - {{ this.busca.periodo.data }}</span>-->
              <!--                    <v-spacer />-->
              <!--                  </v-card-title>-->
              <!--                  <v-card-text>-->
              <!--                    <GraficoBarraDRE :dataSeries="[30000, 15000, 10000, 7000]" :categories="[-->
              <!--                    ['Concluídas'],-->
              <!--                    ['Em aberto'],-->
              <!--                    ['Vencidas'],-->
              <!--                    ['Canceladas'],-->
              <!--                    ]"-->
              <!--                    :colors="['#002D56', '#50A2E0', '#F2B90F', '#951015']" :columnWidth="'45%'" :money="false" />-->
              <!--                  </v-card-text>-->

              <!--                </v-card>-->
              <!--              </v-col>-->
              <!--            </v-row>-->

            </v-card-text>
          </div>

          <div v-if="permissao('controle_visitas_resumo_gerente')">
            <!-- Graficos Mensal, Semanal e Diario -->
            <v-skeleton-loader light v-if="carregando3" type="card-heading, image, actions"/>
            <v-card-text v-else>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                  <v-card class="pt-2">
                    <GraficoSparkline1
                        :dataSeries="[
                            {
                              name: 'Visitas',
                              data: visitasMes.visitasDias
                            }
                        ]"
                        :labels="visitasMes.dias"
                        :colors="['#1D5092']"
                        :title="String(this.visitasCards.TOTAL_MENSAL)"
                        subtitle="Visitas Mensais"
                    />
                  </v-card>

                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                  <v-card class="pt-2">
                    <GraficoSparkline2
                        :dataSeries="[{
                                          name: 'Visitas',
                                          data: visitasSemana.visitasSemana
                                        }]"
                        :labels="visitasSemana.semana"
                        :colors="['#9F272C']"
                        :title="String(this.visitasCards.TOTAL_MEDIA_SEMANAL)"
                        subtitle="Média semanal"
                    />
                  </v-card>

                </v-col>


                <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                  <v-card class="pt-2">
                    <GraficoSparkline3
                        :dataSeries="[{
                          name: 'Visitas',
                          data: visitasDiario.visitasHoras
                        }]"
                        :labels="visitasDiario.horas"
                        :colors="['#F2CB05']"
                        :title="String(this.visitasCards.TOTAL_DIARIO)"
                        subtitle="Visitas diárias"
                    />
                  </v-card>

                </v-col>
              </v-row>

            </v-card-text>
            <v-divider/>

            <v-skeleton-loader light v-if="carregando3" type="card-heading, image, actions"/>
            <v-card-text v-else class="pt-0">
              <v-row class="my-2">
                <v-col cols="12">
                  <v-card class="pa-0">
                    <v-card-title>
                      <span style="font-weight: 500;">Visitas X Vendas</span>
                      <!--                      <v-spacer />-->
                      <!--                      <v-btn :disabled="carregando" class="px-2 mb-1 ml-auto mt-2 mr-2" color="primary"-->
                      <!--                             elevation="0" small @click="abrirDialog('Visitas X Vendas')">-->
                      <!--                        <v-icon size="18">mdi-magnify-plus-outline</v-icon>Ver mais-->
                      <!--                      </v-btn>-->
                    </v-card-title>
                    <v-divider/>
                    <v-card-text v-if="carregando3">
                      <v-skeleton-loader class="mx-auto" width="100%" height="300"
                                         type="image, image"></v-skeleton-loader>
                    </v-card-text>
                    <v-card-text v-else-if="$vuetify.breakpoint.smAndDown" class="pl-2 pr-2">
                      <GraficoLineHorizontal
                          :dataSeries="[
                        { data: this.visitasEVendasGerente.map(v => parseInt(v.QTD_VISITA)) },
                        { data: this.visitasEVendasGerente.map(v => parseInt(v.QTD_PEDIDO)) }
                        ]"
                          :categories="this.visitasEVendasGerente.map(v => v.VENDEDOR)"
                          :maxValues="this.visitasEVendasGerente.map(v => parseInt(v.QTD_VISITA))"
                          :colors="['#1D5092', '#4CAF50']"
                          :seriesLabels="['Visitas Realizadas', 'Pedidos de Venda Efetivados']" :height="1100" :percent="false"
                          :barHeight="'90%'"/>
                    </v-card-text>
                    <v-card-text v-else>
                      <GraficoLineHorizontal
                          :dataSeries="[
                        { data: this.visitasEVendasGerente.map(v => parseInt(v.QTD_VISITA)) },
                        { data: this.visitasEVendasGerente.map(v => parseInt(v.QTD_PEDIDO)) }
                        ]"
                          :categories="this.visitasEVendasGerente.map(v => v.VENDEDOR)"
                          :maxValues="this.visitasEVendasGerente.map(v => parseInt(v.QTD_VISITA))"
                          :colors="['#1D5092', '#4CAF50']"
                          :seriesLabels="['Visitas Realizadas', 'Pedidos de Venda Efetivados']" :height="500" :percent="false"
                          :barHorizontal="false"/>
                    </v-card-text>

                  </v-card>

                </v-col>
              </v-row>

              <v-row class="my-2">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" v-if="$vuetify.breakpoint.mdAndUp">
                  <v-card class="pa-0">
                    <GraficoBarDashVisitas
                        class="pt-4 pl-4 pr-4 pb-0"
                        title="Visitas por Vendedor"
                        :series="[{
                          name: 'Visitas realizadas',
                          data: this.visitasFiliaisGerente.TOTAL_VISITA_VENDEDOR.map(v => v.QTD_VISITA) }]"
                        :categories="this.visitasFiliaisGerente.TOTAL_VISITA_VENDEDOR.map(v => v.VENDEDOR)"
                        :colors="['#1D5092', '#9F272C']"
                        :height="tamanhoGraficoBarras"
                    />
                    <v-divider/>
                    <div>
                      <span
                          class="text_total_visitas text-h5">Total de Visitas filial: {{
                          this.visitasFiliaisGerente.TOTAL_VISITAS
                        }}</span>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" v-else>
                  <v-card class="pa-0" v-if="visitasFiliaisGerente || visitasFiliaisGerente.length === 0">
                    <GraficoBarDashVisitas
                        class="pt-4 pl-4 pr-4 pb-0"
                        title="Visitas por Vendedor"
                        :series="[{
                          name: 'Visitas realizadas',
                          data: this.visitasFiliaisGerente.TOTAL_VISITA_VENDEDOR.map(v => v.QTD_VISITA) }]"
                        :categories="this.visitasFiliaisGerente.TOTAL_VISITA_VENDEDOR.map(v => v.VENDEDOR)"
                        :colors="['#1D5092', '#9F272C']"
                        :height="tamanhoGraficoBarras"
                    />
                    <v-divider/>
                    <div>
                      <span
                          class="text_total_visitas text-h5">Total de Visitas filial: {{
                          this.visitasFiliaisGerente.TOTAL_VISITAS
                        }}</span>
                    </div>
                  </v-card>
                  <span v-else>Não há dados para carregar no gráfico</span>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="6" lg="6">
                  <v-card class="pa-0" v-if="visitasEtapas || visitasEtapas.length === 0">
                    <GraficoDonut
                        class="pa-4"
                        :title="'Visitas por etapa'"
                        :colors="colorPalette"
                        :series="this.visitasEtapas.map(v => parseInt(v.QTD))"
                        :labels="this.visitasEtapas.map(v => v.ETAPA)"
                        :height="600"
                    />
                  </v-card>
                  <v-card v-else>
                    <span>Não há dados para carregar no gráfico</span>
                  </v-card>

                </v-col>
                <v-col v-else cols="12">
                  <v-card>
                    <v-card-text v-if="carregando2">
                      <v-skeleton-loader class="mx-auto" width="100%" height="300"
                                         type="image, image"></v-skeleton-loader>
                    </v-card-text>
                    <v-card-text v-else>
                      <GraficoBarDashVisitas
                          class="pa-0"
                          title="Visitas por Etapa"
                          :series="[{
                          name: 'Visitas realizadas',
                          data: this.visitasEtapas.map(v => parseInt(v.QTD)) }]"
                          :categories="this.visitasEtapas.map(v => v.ETAPA)"
                          :colors="[ '#9F272C']"
                          :height="400"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!-- PLUS A FAZER DEPOIS -->
              <!--            <v-row>-->
              <!--              <v-col cols="12" class="pt-0">-->
              <!--                <v-card color="white">-->
              <!--                  <v-progress-linear indeterminate absolute :active="carregando" />-->
              <!--                  <v-card-title>-->
              <!--                    <span class="text-truncate">Visitas em aberto - {{ this.busca.periodo.data }}</span>-->
              <!--                    <v-spacer />-->
              <!--                  </v-card-title>-->
              <!--                  <v-card-text>-->
              <!--                    <GraficoBarraDRE :dataSeries="[30000, 15000, 10000, 7000]" :categories="[-->
              <!--                    ['Concluídas'],-->
              <!--                    ['Em aberto'],-->
              <!--                    ['Vencidas'],-->
              <!--                    ['Canceladas'],-->
              <!--                    ]"-->
              <!--                    :colors="['#002D56', '#50A2E0', '#F2B90F', '#951015']" :columnWidth="'45%'" :money="false" />-->
              <!--                  </v-card-text>-->

              <!--                </v-card>-->
              <!--              </v-col>-->
              <!--            </v-row>-->

            </v-card-text>
          </div>


        </v-card>

      </v-col>
    </v-row>
  </div>

</template>

<script>
import {mapState} from "vuex";
//import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
//import InputDatePicker from "../Widgets/InputDatePicker";
import GraficoSparkline1 from "@/Components/Widgets/GraficoSparkLineCard.vue";
import GraficoSparkline2 from "@/Components/Widgets/GraficoSparkLineCard.vue";
import GraficoSparkline3 from "@/Components/Widgets/GraficoSparkLineCard.vue";
import GraficoDonut from "@/Components/Widgets/GraficoDonut.vue";
import GraficoBarDashVisitas from "@/Components/Widgets/GraficoBarDashVisitas.vue";
import GraficoLineHorizontal from "@/Components/Widgets/GraficoLineHorizontal.vue";
import axios from "axios";
//import GraficoBarraDRE from "@/Components/Widgets/GraficoBarraDRE.vue";


export default {
  name: "ResumoControleVisitasRede",
  mixins: [mixinFilial],
  components: {

    GraficoLineHorizontal,
    GraficoBarDashVisitas,
    GraficoDonut, GraficoSparkline1, GraficoSparkline2, GraficoSparkline3
  },
  data: () => ({
    sheet: false,
    busca: {
      dtinicio: `${new Date().getFullYear()}-${(new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
      ).getMonth() +
      1 <
      10
          ? "0"
          : "") +
      (new Date().getMonth() + 1)
      }-01`,
      dtfim: new Date().toISOString().split("T")[0],
      idfiliais: [],
      periodo: {data: null}
    },

    tituloDialog: '',
    dialogVisitasVendas: false,

    carregando: true,
    carregando2: true,
    carregando3: true,
    carregando4: true,

    // variaves visao diretoria
    visitasCards: [],
    totalVisitasRede: 0,
    totalVisitasSemana: 0,
    totalVisitasDiario: 0,
    visitasMes: {
      dias: [],
      visitasDias: []
    },
    visitasSemana: {
      semana: [],
      visitasSemana: [],
    },
    visitasDiario: {
      horas: [],
      visitasHoras: []
    },
    valorTotalPedidoVenda: 150326.36,

    visitasFiliais: [],
    visitasEtapas: [],
    visitasEVendas: [],

    // variaveis visao gerente
    visitasFiliaisGerente: [],
    visitasEVendasGerente: [],


    subSemanal: `Visitas do dia - ${new Date().toLocaleDateString()}`,
    periodos: [],

    colorPalette: [
      "#FF4560", // Vermelho
      "#008FFB", // Azul
      "#00E396", // Verde
      "#FEB019", // Amarelo
      "#775DD0", // Roxo
      "#4CAF50", // Verde Escuro
      "#546E7A", // Cinza Azulado
      "#D4526E", // Rosa Escuro
      "#8D5B4C", // Marrom
      "#F86624", // Laranja
      "#A9333A", // Vinho
      "#E2B30D", // Dourado
      "#26A69A", // Turquesa
      "#7D3C98"  // Roxo Escuro
    ],

  }),
  computed: {
    ...mapState(["backendUrl", "usuario", "pgLimit"]),
    filiaisFiltro() {
      if (this.filiais.lista) {
        return this.filiais.lista.filter((v) => {
          return (
              v.idfilial !== 6 &&
              v.idfilial !== 12 &&
              v.idfilial !== 13 &&
              v.idfilial !== 25 &&
              v.idfilial !== 28 &&
              v.idfilial !== 29 &&
              v.idfilial !== 33 &&
              v.idfilial !== 37 &&
              v.idfilial !== 39 &&
              v.idfilial !== 40 &&
              v.idfilial !== 42

          );
        });
      } else {
        return [];
      }
    },
    tamanhoGraficoBarras() {
      return this.$vuetify.breakpoint.mdAndUp ? 600 : 800;
    }
  },
  methods: {

    async carrega() {
      await this.listaTotaisVisitas();
      await this.listarVisitasEtapas();

      if (this.permissao('controle_visitas_resumo_diretoria')) {
        await this.listarComparativoVisitasVendas();
        await this.listarVisitasFilial();
      }

      if (this.permissao('controle_visitas_resumo_gerente')) {
        await this.listarVisitasFilialGerente();
        await this.listarComparativoGerente();
      }

    },
    formatarValor(valor) {
      return valor.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    listarPeriodos() {
      return axios
          .post(`${this.backendUrl}dashboard/controle/visita/periodo`)
          .then((res) => {
            let datas = JSON.parse(res.data)
            this.periodos = datas.map(obj => String(obj.PERIODO));
            this.busca.periodo.data = this.periodos[0];
          });
    },
    listaTotaisVisitas() {
      this.visitasCards = [];
      this.visitasDiario.horas = null;
      this.visitasDiario.visitasHoras = null;
      this.visitasMes.visitasDias = null;
      this.visitasMes.dias = null;
      this.visitasSemana.semana = null;
      this.visitasSemana.visitasSemana = null;
      this.totalVisitasDiario = 0;
      this.totalVisitasSemana = 0;
      this.totalVisitasRede = 0;

      this.carregando = true;
      this.carregando3 = true;
      return axios
          .post(`${this.backendUrl}dashboard/controle/visita/totaiscard`, {
            periodo: this.busca.periodo.data,
          })
          .then((res) => {
            let dados_tratados = JSON.parse(res.data);
            this.visitasCards = dados_tratados.TOTAIS_CARDS;

            this.totalVisitasRede = this.visitasCards.TOTAL_MENSAL;
            this.totalVisitasSemana = this.visitasCards.TOTAL_MEDIA_SEMANAL;
            this.totalVisitasDiario = this.visitasCards.TOTAL_DIARIO;

            this.visitasMes.dias = this.visitasCards.MENSAL.map(obj => obj.DATA);
            this.visitasMes.visitasDias = this.visitasCards.MENSAL.map(obj => obj.QTD_VISITAS);
            this.visitasSemana.semana = this.visitasCards.SEMANAL.map(obj => obj.SEMANA);
            this.visitasSemana.visitasSemana = this.visitasCards.SEMANAL.map(obj => obj.QTD_VISITAS);
            this.visitasDiario.horas = this.visitasCards.DIARIO.map(obj => obj.HORA);
            this.visitasDiario.visitasHoras = this.visitasCards.DIARIO.map(obj => obj.QTD_VISITAS_HORA);

            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarVisitasFilial() {
      this.visitasFiliais = [];
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}dashboard/controle/visita/totaLfilial`, {
            periodo: this.busca.periodo.data,
          })
          .then((res) => {
            let dados_tratados = JSON.parse(res.data);
            this.visitasFiliais = dados_tratados;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    listarVisitasEtapas() {
      this.visitasEtapas = [];
      this.carregando = true;
      this.carregando2 = true;
      this.carregando3 = true;

      return axios
          .post(`${this.backendUrl}dashboard/controle/visita/etapaobra`, {
            periodo: this.busca.periodo.data,
          })
          .then((res) => {
            let dados_tratados = JSON.parse(res.data);
            this.visitasEtapas = dados_tratados;

            this.carregando = false;
            this.carregando2 = false;
            this.carregando3 = false;
          })
          .catch(() => {
            this.carregando = false;
            this.carregando2 = false;
            this.carregando3 = false;
          });
    },
    listarComparativoVisitasVendas() {
      this.visitasEVendas = [];
      this.carregando = true;

      return axios
          .post(`${this.backendUrl}dashboard/controle/visita/visitaxpedido`, {
            periodo: this.busca.periodo.data,
          })
          .then((res) => {

            let dados_tratados = JSON.parse(res.data);
            this.visitasEVendas = dados_tratados;

            this.carregando2 = false;
          })
          .catch(() => {
            this.carregando2 = false;
          });
    },

    listarVisitasFilialGerente() {
      this.visitasFiliaisGerente = [];
      this.carregando3 = true;
      return axios
          .post(`${this.backendUrl}dashboard/controle/visita/totaLvendedor`, {
            periodo: this.busca.periodo.data,
          })
          .then((res) => {
            let dados_tratados = JSON.parse(res.data);
            this.visitasFiliaisGerente = dados_tratados;
            this.carregando3 = false;
          })
          .catch(() => {
            this.carregando3 = false;
          });

    },
    listarComparativoGerente() {
      this.visitasEVendasGerente = [];
      this.carregando3 = true;

      return axios
          .post(`${this.backendUrl}dashboard/controle/visita/visitaxpedidovendedor`, {
            periodo: this.busca.periodo.data,
          })
          .then((res) => {
            let dados_tratados = JSON.parse(res.data);
            this.visitasEVendasGerente = dados_tratados;

            this.carregando3 = false;
          })
          .catch(() => {
            this.carregando3 = false;
          });

    },


    abrirDialog(tituloDialog) {

      if (tituloDialog === 'Visitas X Vendas') {
        this.tituloDialog = tituloDialog;
        this.dialogVisitasVendas = true;
      }
    },
    limpar() {
      this.visitasDiario.horas = null;
      this.visitasDiario.visitasHoras = null;
      this.visitasMes.visitasDias = null;
      this.visitasMes.dias = null;
      this.visitasSemana.semana = null;
      this.visitasSemana.visitasSemana = null;
      this.totalVisitasDiario = 0;
      this.totalVisitasSemana = 0;
      this.totalVisitasRede = 0;
      this.visitasFiliais = [];
      this.visitasEtapas = [];
      this.visitasEVendas = [];
    },
    async init() {

      await this.listarPeriodos();
      await this.listaTotaisVisitas();
      await this.listarVisitasEtapas();

      if (this.permissao('controle_visitas_resumo_diretoria')) {
        await this.listarComparativoVisitasVendas();
        await this.listarVisitasFilial();
      }

      if (this.permissao('controle_visitas_resumo_gerente')) {
        await this.listarVisitasFilialGerente();
        await this.listarComparativoGerente();
      }

    },

  },
  mounted() {
  },
  beforeDestroy() {
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>

.colors-dashboard {
  background-color: #e6e8ea;
}

.text_total_visitas {
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  height: 60px; /* Defina uma altura para visualizar melhor */
  font-weight: 500;
}
</style>