<template>
  <VueApexCharts type="area" :height="160" :options="chartOptions" :series="series" />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "GraficoSparkline",
  components: { VueApexCharts },
  props: {
    dataSeries: Array,
    labels: Array,
    colors: Array,
    title: String,
    subtitle: String
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "area",
          height: 160,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "straight",
        },
        fill: {
          opacity: 1,
        },
        yaxis: {
          min: 0,
        },
        xaxis: {
          type: "string",
        },
        colors: [],
        title: {
          text: "",
          offsetX: 30,
          style: {
            fontSize: "24px",
            cssClass: "apexcharts-yaxis-title",
          },
        },
        subtitle: {
          text: "",
          offsetX: 30,
          style: {
            fontSize: "14px",
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
    };
  },

  methods: {
    init() {
      this.series = this.dataSeries;
      this.chartOptions.labels = this.labels;
      this.chartOptions.colors = this.colors;
      this.chartOptions.title.text = this.title;
      this.chartOptions.subtitle.text = this.subtitle;
    },
  },
  created() {
    this.init();
  },
};
</script>
