<template>
  <div>
    <apexchart
        :options="chartOptions"
        :series="series"
        type="bar"
        :height="computedHeight"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: "Bar Chart",
    },
    height: {
      type: Number,
      default: 450,
    },
    series: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      default: () => ["#008FFB"],
    },
  },
  data() {
    return {
      isMobile: window.innerWidth < 640, // Detecta se a tela é pequena (xs ou sm)
    };
  },
  computed: {
    computedHeight() {
      return this.isMobile ? 650 : this.height; // Reduz a altura do gráfico no mobile
    },
    chartOptions() {
      return {
        chart: {
          type: "bar",
          height: this.computedHeight,
          toolbar: { show: false }, // Esconde barra de ferramentas
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            borderRadiusApplication: "start",
            horizontal: true,
            columnWidth: "80%",
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: this.isMobile ? "10px" : "12px", // Reduz fonte no mobile
            colors: ["#ffffff"],
          },
        },
        xaxis: {
          categories: this.categories,
          labels: {
            style: {
              fontSize: this.isMobile ? "10px" : "12px",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: this.isMobile ? "12px" : "14px",
            },
          },
        },
        colors: this.colors,
        title: {
          text: this.title,
          align: "center",
          style: {
            fontSize: this.isMobile ? "18px" : "22px", // Reduz título no mobile
            fontWeight: "bold",
            color: "#222",
          },
        },
      };
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 640; // Atualiza o estado ao redimensionar a tela
    },
  },
};
</script>
